<template>
  <v-row justify="center">
    <v-col md="6">
      <ContactForm />
      <!-- <ProjectForm /> -->
      <PasswordForm />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Account",
  components: {
    ContactForm: () => import("./ContactForm/ContactForm"),
    // ProjectForm: () => import("./ProjectForm/ProjectForm"),
    PasswordForm: () => import("./PasswordForm/PasswordForm"),
  },
};
</script>

<style src="./Account.scss" lang="scss" scoped></style>